import React from "react";
import {Box, Button} from "@mui/material";
import {AutoGraph} from "@mui/icons-material";

export const AltroQuestion = () => {
  return(
      <Box>
          <Button endIcon={<AutoGraph sx={{fontSize: '3rem'}}/>}
                  sx={{fontSize: '1.5rem'}}
                  variant={'default'}
                  href={"https://real-time-report.plesh.co/inda-2024"} target={"_blank"}>
              VAI AI REPORT
          </Button>
      </Box>
  )
}